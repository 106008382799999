import * as React from "react";
import PropTypes from "prop-types";
import logo from '../images/logo.png';
import smallLogo from '../images/logo-mobile.svg';
import cross from '../images/cross.svg';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { motion, AnimateSharedLayout } from "framer-motion";
import { Link } from "gatsby";
import { Zoom, Slide, Fade } from 'react-reveal';

const spring = {
  type: "spring",
  stiffness: 800,
  damping: 40,
  //default: { duration: .3 },
};

const Header = () => (
  <AnimateSharedLayout>
    <header class="header container mx-auto pt-5 font-medium text-xs lg:text-base">
      <div class="flex items-center flex-row lg:flex-row site-header py-2 sm:py-8 lg:py-0">
        <div class="flex flex-1 text-center">
          <Link className="relative lg:pr-32" to="/" hex="#FB4B06">
            <div class="hidden lg:block">
              <Fade delay={100} top>
                <img
                  style={{ width: 260 }}
                  src={logo}
                />
              </Fade>
            </div>
            <div class="lg:hidden">
              <Fade delay={100} top>
                <img
                  style={{ width: 65 }}
                  src={smallLogo}
                />
              </Fade>
            </div>
            <div class="absolute -left-auto -right-0 -top-2 -bottom-2 hidden lg:block">
              {typeof window !== `undefined` && window.location.pathname === '/' && (
                <motion.div
                  layoutId="nav"
                  initial={false}
                  transition={spring}
                  style={{ width: 90 }}
                >
                  <img src={cross} alt="X" />
                </motion.div>
              )}
            </div>
          </Link>
        </div>
        <div class="flex items-center">
          <ul class="flex">
            <li class="font-sans font-medium tracking-wide sm:tracking-widest uppercase mr-2 sm:mr-8 lg:mr-12 relative">
              <div class="absolute left-1/2 -right-auto -top-2 -bottom-2">
                {typeof window !== `undefined` && window.location.pathname === '/people/' && (
                  <motion.div
                    layoutId="nav"
                    initial={false}
                    transition={spring}
                    style={{ width: 60, marginLeft: -30 }}
                  >
                    <img src={cross} alt="X" />
                  </motion.div>
                )}
              </div>
              <Fade delay={50}>
                <Link to="/people/" hex="#FB4B06" className="z-1 relative">people</Link>
              </Fade>
            </li>
            <li class="font-sans font-medium tracking-wide sm:tracking-widest uppercase mr-2 sm:mr-8 lg:mr-12 relative">
              <div class="absolute left-1/2 -right-auto -top-2 -bottom-2">
                {typeof window !== `undefined` && window.location.pathname === '/build/' && (
                  <motion.div
                    layoutId="nav"
                    initial={false}
                    transition={spring}
                    style={{ width: 60, marginLeft: -30 }}
                  >
                    <img src={cross} alt="X" />
                  </motion.div>
                )}
              </div>
              <Fade delay={50}>
                <Link to="/build/" hex="#FB4B06" className="z-1 relative">build</Link>
              </Fade>
            </li>
            <li class="font-sans font-medium tracking-wide sm:tracking-widest uppercase relative">
              <div class="absolute left-1/2 -right-auto -top-2 -bottom-2">
                {typeof window !== `undefined` && window.location.pathname === '/companies/' && (
                  <motion.div
                    layoutId="nav"
                    initial={false}
                    transition={spring}
                    style={{ width: 60, marginLeft: -30 }}
                  >
                    <img src={cross} alt="X" />
                  </motion.div>
                )}
              </div>
              <Fade delay={50}>
                <Link to="/companies/" hex="#FB4B06" className="z-1 relative">companies</Link>
              </Fade>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </AnimateSharedLayout>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `HG Ventures`,
};

export default Header;