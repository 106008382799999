import * as React from "react";
import PropTypes from "prop-types";
import Header from "../components/header";
import Footer from "../components/footer";

const Layout = ({ children }) => {
  return (
    <div class="bg-gray-100">
      <div class="bg-white px-4 pb-4 lg:px-8 lg:pb-8 container lg:mx-auto">
        <Header />
        <main class="overflow-hidden relative">{children}</main>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
