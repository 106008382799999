import * as React from "react";
import email from '../images/email.svg';

const Footer = () => (
    <footer class="bg-black text-white font-sans font-normal text-md lg:text-md tracking-wider">
        <div class="container mx-auto pt-10 lg:px-10 lg:pt-24">
            <div class="grid grid-cols-1 lg:space-x-8 lg:grid-cols-9">
                <div class="mb-10 lg:mb-0 lg:col-span-3">
                    <h2 class="uppercase font-sans font-medium mb-1">
                        LONDON OFFICES
                    </h2>
                    -
                    <address class="font-sans font-thin not-italic mt-5">
                        4 Tabernacle Street<br />
                        London<br />
                        EC2A 4LU
                    </address>

                    <address class="font-sans font-thin not-italic mt-5">
                        66 Wilton Road<br />
                        Victoria<br />
                        London<br />
                        SW1V 1DE
                    </address>
                </div>
                <div class="lg:col-span-3">
                    <h2 class="uppercase font-sans font-medium mb-1">
                        CHIPPING CAMPDEN OFFICE<br />
                    </h2>
                    -
                    <address class="font-sans font-thin not-italic mt-5">
                        Camperdene House<br />
                        High Street<br />
                        Chipping Campden<br />
                        Gloucestershire<br />
                        GL55 6AT
                    </address>
                </div>
                <div class="mt-10 lg:mt-0 lg:text-right lg:col-span-3">
                    <a href="mailto:enquiries@huntergatherer-group.com">
                        <h2 class="uppercase font-sans font-medium mb-1 flex align-center lg:justify-end">
                            <div>contact us </div>
                            <img style={{ width: 26 }} src={email} class="inline ml-4 mt-0" />
                        </h2>
                    </a>
                    -
                    <div class="font-sans font-thin not-italic mt-5">
                        <a href="tel:01386 841179">01386 841179</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mx-auto lg:mt-32 pb-10 lg:px-10 mt-10 lg:pt-0">
            <div class="font-sans font-thin text-sm">
                &copy; {new Date().getFullYear()} HG Ventures
            </div>
            <div></div>
        </div>
    </footer>
);

Footer.propTypes = {

};

Footer.defaultProps = {

};

export default Footer;